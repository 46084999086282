import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Modal, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import DatePicker from "react-multi-date-picker";
const $ = require("jquery")
import { DateObject } from "react-multi-date-picker";
$.Datatable = require("datatables.net");
export default function actionHistory() {

    const [data, setUser] = useState()
        const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
      const [values, setValues] = useState([
    new DateObject().subtract(4, "days"),
    new DateObject().add(4, "days")
  ])
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
        var baseUrl = beckendLocalApiUrl;
    } else {
        baseUrl = beckendLiveApiUrl;
    }



    //use for pagination..
    let [limit, setLimit] = useState(10);

    const setpageLimit = (event) => {
        let key = event.target.value
        setLimit(key);
    }
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    //user for searching..
    const [searchList, setSearchList] = useState(0);
    const [searchType, setSearchType] = useState(0);

    //react paginate..
    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        setPageNumber(currentPage);
        // scroll to the top
        //window.scrollTo(0, 0)
    };

    //   searching handler
    const searchHandler = (event) => {
        let key = event.target.value
        setSearchList(key);
    }

    const Alluser = () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.get(baseUrl + `User/all/actions?page=${pageNumber}&_limit=${limit}&_q=${searchList}&_stype=${searchType}&FROM_DATE=${values[0]}&TO_DATE=${values[1]}`, { headers })
            .then((res) => {
                //setUser(res.data)
                setUser(res.data.admin)
                setNumberOfPages(res.data.totalPages)
                //$('table').dataTable();
            })
    }


    const [type, setType] = useState()
    const [bonus, setBonus] = useState()
        const [reason, setReason] = useState()
    const [ids, setId] = useState()
    

    useEffect(() => {
        Alluser()
    }, [pageNumber, limit, searchList, searchType,values])
  const dateFormat = (e) => {

        const date = new Date(e);
        const newDate = date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
        return newDate;
    }
    return (
        <>
        
            {/* <h4 className='font-weight-bold my-3'>ALL CHALLANGES</h4> */}
            <div className="row ">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body text-light" style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}>
                            <h4 className="card-title ">Action Report</h4>
                            {/* searching */}
                              <DatePicker value={values} onChange={setValues} range />
                            <div className='row'>
                                <select className='form-control col-sm-3 m-2' id='searchType' name='searchtype' onChange={(e) => setSearchType(e.target.value)}>
                                    <option value="0">Select Search by</option>
                                  
                                    <option value="Phone">Phone</option>
                                    <option value="_id"> Id</option>
                                </select>
                                <input type='text' placeholder='Search...' className='form-control col-sm-4 m-2' onChange={searchHandler} />
                                <select className='form-control col-sm-1 m-1 bg-dark text-light' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                                    <option value="10">Set limit</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                            <div className="table-responsive">
                                <table className="table text-light">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th> ID</th>
                                      
                                            <th> Mobile </th>
                                            <th> Role </th>
                                            
                                             <th> Date </th>
                                             <th> Action </th>
                                        </tr>
                                    </thead>



                                    <tbody>
                                        {data && data.map((data, key) => (

                                            <tr role="row" className="odd">
                                                <td className="sorting_1">{key + 1}</td>
                                                <td>{data._id}</td>
                                            
                                                <td>{data.Phone}</td>
                                                <td>
  {data.role === 'Admin' && (
    <span className="badge badge-primary text-white text-xs px-2 py-1 rounded-full">
      Admin
    </span>
  )}
  {data.role === 'Agent' && (
    <span className="badge badge-success text-white text-xs px-2 py-1 rounded-full">
      Agent
    </span>
  )}
</td>
                                              
                                                  <td>
                                                   {dateFormat(data.timestamp).split(",")[0]}
                                                </td>
                                                 <td>
                                                  <button className="btn btn-primary" onClick={()=>window.open(data.action,"_blank")}> View</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div>

                            <div className='mt-4'>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={numberOfPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-center"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
